
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint "@typescript-eslint/no-var-requires": "off" */
import React from 'react';
import type { GetStaticProps } from 'next';
import { LandingPage } from '../templates/LandingPage';
import { withPrepass } from '../utils/server';
import type { GenericPage } from '../types';
import { landingPage } from '@/schemas';
import debug from 'debug';
import { defaultLocale, previewDataSchema } from '@/constants';
import { query as queryFn } from '@/utils/contentful/query';
import { normalizeObjectFieldKey, transformLocale } from '@/utils';
type PageProps = {
    preview?: boolean;
    page: typeof type;
};
const [query, type] = landingPage.builder
    .filter('fields.urlHandle == "/"')
    .first()
    .use();
export const maxDuration = 30;
const Home: GenericPage<PageProps> = ({ preview, page }) => {
    return <LandingPage preview={preview} page={page}/>;
};
const getStaticProps: GetStaticProps<PageProps> = async ({ preview, previewData, locale, }) => {
    const debugInstance = debug('preview');
    const previewDataResult = previewDataSchema.safeParse(previewData);
    const isPreview = preview &&
        previewDataResult.success &&
        previewDataResult.data.preview_slug.length === 0;
    debugInstance(`isPreview: %O`, previewData);
    const page = await queryFn<typeof type | null>(normalizeObjectFieldKey(transformLocale(locale ?? defaultLocale.id)), query, {}, { preview: isPreview });
    if (!page) {
        return {
            notFound: true,
        };
    }
    const result = await withPrepass({
        props: {
            page,
            preview: isPreview,
        },
        locale,
        preview: isPreview,
        previewData: isPreview ? previewData : undefined,
    }, Home);
    return result;
};
export default Home;

    async function __Next_Translate__getStaticProps__18f39bb220c__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18f39bb220c__ as getStaticProps }
  